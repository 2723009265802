import React from "react"
import { Box, Heading, List, ListItem, Link, SimpleGrid } from "@chakra-ui/core"
import { Link as GatsbyLink } from "gatsby"
import Layout from "../components/layout"
import { SectionHeading, Paragraph, Bold } from "../components/ui-elements"
import SEO from "../components/seo"
import VirtualAssistant from "./virtual-assistant"

const CookiesPolicy = () => {
  const tableCellStyles = {
    borderColor: "theme-dark",
    borderWidth: "1px",
    borderStyle: "solid",
    py: 2,
    px: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    h: "100%",
    w: "100%",
  }
  return (
    <Layout>
      <SEO title="Cookies Policy - VERIFiSMART" description="Explore VERIFiSMART cookies policy to learn how cookies enhance your website experience. Discover the types of cookies we use and your privacy options for a better browsing experience."/>
      <Box py="16">
        <Box color="theme-dark" maxW="containers.xl" mx="auto" my="16" w="100%">
          <SectionHeading>Cookies Policy</SectionHeading>
          <Paragraph>
            The Website uses cookies as detailed below and for the purposes
            herein described:
          </Paragraph>
          <Heading size="lg">WHAT ARE COOKIES?</Heading>
          <Paragraph>
            Cookies are small files which are stored on your computer, they hold
            a modest amount of data specific to you and allows a server to
            deliver a page tailored to you on your computer, hard drive,
            smartphone or tablet (hereinafter referred to as, “Device”). Later
            on, if you return to the Website, it can read and recognise the
            cookies. Primarily, they are used to operate or improve the way the
            Website works as well as to provide business and marketing
            information to the Website owner.
          </Paragraph>
          <Heading size="lg">
            TYPES OF COOKIES COLLECTED (ACCORDING TO THEIR PURPOSE)
          </Heading>
          <Heading mt="16" size="md">
            A. Technical Cookies:
          </Heading>
          <List
            fontWeight="200"
            ml="4"
            stylePos="outside"
            as="ol"
            type="a"
            styleType="a"
          >
            <ListItem my="4">
              Cookies strictly necessary for the operation of the website:
              essential to enable website browsing and the functioning of
              various features. Without these you cannot use search services,
              shopping comparisons, and other services available on the website.
            </ListItem>
            <ListItem my="4">
              Customization cookies: These are used to facilitate navigation of
              the website, to record the options you have chosen on the website
              and provide more personalized features. In some cases we allow
              advertisers or third parties to place cookies on the Website to
              provide personalized content and services. In any case, the use of
              the Website constitutes acceptance of the use of this type of
              cookie. If cookies are blocked, we cannot guarantee the optimal
              functioning of the website.
            </ListItem>
            <ListItem my="4">
              Cookies for statistical purposes and to measure traffic: These
              collect information on the use of the website, the pages you visit
              and any errors that may occur during navigation. We also use
              cookies to recognize the source of your visit to the Website.
              These cookies do not collect information that can be used to
              identify you. All information is collected anonymously and used to
              help improve the operation of the website. Therefore, our cookies
              do not contain personal data. In some cases, some of these cookies
              are managed on our behalf by third parties, but they are not
              allowed to use them for purposes other than those mentioned above.
              The use of the Website constitutes acceptance of the use of this
              type of cookie. If they are blocked, we cannot guarantee the
              proper operation of the website.
            </ListItem>
          </List>
          <Heading mt="16" size="md">
            B. Cookies for advertising and re-marketing purposes:
          </Heading>
          <Paragraph>
            Used to collect user information in order to display advertisements
            on the website or third party sites.
          </Paragraph>
          <Paragraph>
            To that end, we can also use the services of a third party in order
            to collect data and/or publish ads when you visit the Website. These
            companies often use anonymous and aggregated information (not
            including, for example, your name, address, email address or
            telephone number) regarding visits to the Website and others in
            order to publish ads about goods and services of interest to you.
          </Paragraph>
          <Heading mt="16" size="md">
            C. Social Cookies:
          </Heading>
          <Paragraph>
            These cookies allow the Website to be shared and allow for "Like"
            clicks on social networking sites like Facebook, Twitter, Google +
            etc.
          </Paragraph>
          <Paragraph>
            The lifespan of the cookies we use may vary. In particular, there
            are session cookies that are only valid for a single search or
            browsing session and disappear when the browser is closed; and
            persistent cookies, which have a variable lifespan. The maximum
            lifespan of cookies in this second category is <Bold>5 years.</Bold>
          </Paragraph>
          <Heading size="lg">
            TYPES OF COOKIES COLLECTED (ACCORDING TO THEIR PURPOSE)
          </Heading>
          <Paragraph>
            Users should be aware that if cookies are not enabled on your
            device, the Website experience may be limited and the use of our
            services and navigation could be impeded.
          </Paragraph>
          <Paragraph>
            There are several ways to manage cookies. By modifying browser
            settings, you can choose to disable cookies or receive a warning
            before accepting one. Also, users can delete all cookies stored in
            the cookie folder of their browser. Each browser has different
            procedures for managing configuration. You can find out further
            information at:
          </Paragraph>
          <List
            fontWeight="200"
            ml="4"
            stylePos="outside"
            as="ul"
            styleType="disc"
          >
            <ListItem my="4">
              <Link
                href="https://www.google.com/intl/en/chrome/privacy/"
                isExternal
                textDecoration="underline"
              >
                Google
              </Link>
            </ListItem>
            <ListItem my="4">
              <Link
                href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&redirectslug=Cookies#w_cookie-settings"
                isExternal
                textDecoration="underline"
              >
                Firefox
              </Link>
            </ListItem>
            <ListItem my="4">
              <Link
                href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies"
                isExternal
                textDecoration="underline"
              >
                Windows
              </Link>
            </ListItem>
            <ListItem my="4">
              <Link
                href="https://www.apple.com/safari/#security"
                isExternal
                textDecoration="underline"
              >
                Safari
              </Link>
            </ListItem>
          </List>
          <Heading mt="16" size="lg">
            HOW ARE THIRD PARTY COOKIES ENABLED/DISABLED?
          </Heading>
          <Paragraph>
            Third-party cookies are not installed by us; they are installed by
            our business partners or other parties when you visit the Website.
            Therefore, we suggest users consult the third-party’s website for
            information on how they install cookies and how they are managed.
          </Paragraph>
          <Heading mt="16" size="lg">
            WHAT “COOKIES” ARE USED ON THIS WEBSITE?
          </Heading>
          <SimpleGrid
            my="16"
            columns={6}
            borderColor="theme-dark"
            borderWidth="1px"
            borderStyle="solid"
          >
            <>
              <Box
                {...tableCellStyles}
                bg="theme-dark"
                color="white"
                fontWeight="600"
                borderColor="theme-purple"
              >
                Cookie
              </Box>
              <Box
                {...tableCellStyles}
                bg="theme-dark"
                color="white"
                fontWeight="600"
                borderColor="theme-purple"
              >
                Cookie Purpose
              </Box>
              <Box
                {...tableCellStyles}
                bg="theme-dark"
                color="white"
                fontWeight="600"
                borderColor="theme-purple"
              >
                Name
              </Box>
              <Box
                {...tableCellStyles}
                bg="theme-dark"
                color="white"
                fontWeight="600"
                borderColor="theme-purple"
              >
                Domain
              </Box>
              <Box
                {...tableCellStyles}
                bg="theme-dark"
                color="white"
                fontWeight="600"
                borderColor="theme-purple"
              >
                Cookie Type{" "}
              </Box>
              <Box
                {...tableCellStyles}
                bg="theme-dark"
                color="white"
                fontWeight="600"
                borderColor="theme-purple"
              >
                Collection Period
              </Box>
            </>
            <>
              <Box {...tableCellStyles}>Web Analytics</Box>
              <Box {...tableCellStyles}>
                tracks each user’s amount of visits, and the time of the first,
                the previous, and the current visit.
              </Box>
              <Box {...tableCellStyles}>Google Analytics</Box>
              <Box {...tableCellStyles}>.google.com</Box>
              <Box {...tableCellStyles}>Third party</Box>
              <Box {...tableCellStyles}>1 year</Box>
            </>
            <>
              <Box {...tableCellStyles}>
                Cookie to monitor the reliability of companies’ integration
              </Box>
              <Box {...tableCellStyles}>
                Used to monitor the reliability of companies’ integration points
                with the website.
              </Box>
              <Box {...tableCellStyles}></Box>
              <Box {...tableCellStyles}></Box>
              <Box {...tableCellStyles}>First party</Box>
              <Box {...tableCellStyles}>1 month</Box>
            </>
            <>
              <Box {...tableCellStyles}>Cookie banner’ cookie</Box>
              <Box {...tableCellStyles}>
                Used to remember if a web visitor has dismissed the “cookie
                banner” showing up on top of web pages.
              </Box>
              <Box {...tableCellStyles}>Pop up notice cookie</Box>
              <Box {...tableCellStyles}>
                cookieconsent_status.verifismart.com
              </Box>
              <Box {...tableCellStyles}>First party </Box>
              <Box {...tableCellStyles}>1 year</Box>
            </>
          </SimpleGrid>
          <Heading mt="16" size="md">
            Definitions:
          </Heading>
          <List
            fontWeight="200"
            ml="4"
            stylePos="outside"
            as="ul"
            styleType="disc"
          >
            <ListItem my="4">
              “First Party” Cookie – A cookie placed by the Website
            </ListItem>
            <ListItem my="4">
              “Third Party” Cookie – A cookie placed by another domain (e.g. not
              the Website)
            </ListItem>
            <ListItem my="4">
              “Permanent” Cookie –   The cookie information is permanently
              stored on your device
            </ListItem>
          </List>
          <Paragraph>
            We don’t seek to collect your personal information and where we do,
            we strive to anonymise your personal information as soon as
            practicable.
          </Paragraph>
          <Paragraph>
            The information contained in the above list of cookies regarding
            Third party Cookies, has been provided by the companies which
            generate them. These companies have their own privacy policies in
            which they set forth both their own declarations as well as
            applicable disabling systems.
          </Paragraph>
          <Paragraph>
            We are not responsible for the contents and accuracy of third-party
            cookie policies contained in this Cookie Policy.
          </Paragraph>
          <Heading mt="16" size="lg">
            YOUR CONSENT
          </Heading>
          <Paragraph>
            It is understood that by clicking accept in the Cookies pop-up
            notice, closing it or continuing to navigate the Website after it
            has appeared, you are consenting to the use of cookies, as
            specifically indicated in the pop-up notice on the front page
            (“Homepage”) reporting the existence of our Cookie Policy.
          </Paragraph>
          <Paragraph>
            We may also use the services of a third party in order to collect
            data and/or publish ads when you visit the Website. These companies
            often use anonymous and aggregated information (not including, for
            example, your name, address, email address or telephone number)
            regarding visits to the Website and others in order to publish ads
            about goods and services of interest to you.
          </Paragraph>
          <Paragraph>
            If you would like more information about how we use and process
            Cookies, please refer to our{" "}
            <Bold
              textDecoration="underline"
              to="/privacy-policy"
              as={GatsbyLink}
            >
              Privacy Policy.
            </Bold>
          </Paragraph>
          <Paragraph>
            If you want to learn more about our services, please refer to our{" "}
            <Bold
              textDecoration="underline"
              to="/about-us"
              as={GatsbyLink}
            >
              About Us.
            </Bold>
          </Paragraph>
        </Box>
      </Box>
      <VirtualAssistant />
    </Layout>
  )
}

export default CookiesPolicy
